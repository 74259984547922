import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout.en";
import SEO from "../components/seo";
import style from "./avisos.module.scss"
import imgPage from "../images/headers/Logos_Ale.svg"
import Header from "../components/header";
import Img from "gatsby-image"

export default ({data}) => {

    return (
        <Layout footer='default'>

            <SEO title="Derechos Arco"/>
            <Header LogoPage={imgPage}/>

            <div className={style.avisosPortada}>
                <Img style={{maxHeight: '400px'}} fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <div className={style.avisosContent}>
                <h2>ARCO (ACCESS, RECTIFICATION, CANCELLATION AND OPPOSITION) RIGHTS</h2>
                <p>ARCO Rights Application<br/>
                    Fill out the form with the fields described here and send your request to the following account <a href="mailto:atenciondatos@aerolineasejecutivas.com">atenciondatos@aerolineasejecutivas.com</a><br/><br/>
                    Download the application <a href={data.arco.publicURL} download>here</a>.
                </p>
            </div>
        </Layout>
    )

}

export const query = graphql`
    query ImagesDerechosEn {
    portada: file(relativePath: {eq: "avisos/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   arco: file(relativePath: {regex: "/ARCO_EN/"}) {
            publicURL
      }
}`

